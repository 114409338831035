import ModelErrors from './errors'

export default class ModelBase {
  constructor({ i18nModelName, columns }, attributes) {
    columns.forEach((key) => {
      this[key] = attributes[key]
    })

    this.i18nModelName = i18nModelName
    this.errors = new ModelErrors()
  }

  isNewRecord() {
    return !this.id
  }

  isDataLoaded() {
    return !!this.id
  }

  // for i18n
  static modelNameLocaleText() {
    const instance = new this()

    if (!instance.i18nModelName) return console.error('no i18nModelName')

    return instance.modelNameLocaleText()
  }

  static attributeLocaleText(key, value) {
    const instance = new this()

    if (!instance.i18nModelName) return console.error('no i18nModelName')

    return window.currentI18n.attributeLocaleText(
      instance.i18nModelName,
      key,
      value
    )
  }

  static enumLocaleText(key, value) {
    const instance = new this()

    if (!instance.i18nModelName) return console.error('no i18nModelName')

    return window.currentI18n.enumLocaleText(instance.i18nModelName, key, value)
  }

  static enumLocaleList(key) {
    const instance = new this()

    if (!instance.i18nModelName) return console.error('no i18nModelName')

    return window.currentI18n.enumLocaleList(instance.i18nModelName, key)
  }

  static aasmStateLocaleText(key, value) {
    const instance = new this()

    if (!instance.i18nModelName) return console.error('no i18nModelName')

    return window.currentI18n.aasmStateLocaleText(
      instance.i18nModelName,
      key,
      value
    )
  }

  static aasmStateLocaleList(key) {
    const instance = new this()

    if (!instance.i18nModelName) return console.error('no i18nModelName')

    return window.currentI18n.aasmStateLocaleList(instance.i18nModelName, key)
  }

  modelNameLocaleText() {
    if (!this.i18nModelName) return console.error('no i18nModelName')

    return window.currentI18n.modelLocaleText(this.i18nModelName)
  }

  attributeLocaleText(attribute) {
    if (!this.i18nModelName) return console.error('no i18nModelName')

    return window.currentI18n.attributeLocaleText(this.i18nModelName, attribute)
  }

  enumLocaleText(key) {
    if (!this.i18nModelName) return console.error('no i18nModelName')

    return window.currentI18n.enumLocaleText(this.i18nModelName, key, this[key])
  }

  aasmStateLocaleText(key) {
    if (!this.i18nModelName) return console.error('no i18nModelName')

    return window.currentI18n.aasmStateLocaleText(
      this.i18nModelName,
      key,
      this[key]
    )
  }
}
